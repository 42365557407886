

.about_page{
  min-height: 100vh;
  background: #fff;
  color: #000000;
  font-size: 16px;
  .body_wrap{
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
    .title{
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1;
    }
    .des{
      strong{
        font-size: 24px;
        font-weight: bold;
      }
      font-size: 22px;
      font-weight: 300;
      line-height: 1.6;
      margin-bottom: 70px;
    }
  }
}


.ques_wrap{
  display: flex;
  align-items: center;
  font-size: 21px;
  .left{
    width: 150px;
  }
  .right{

  }

  @media screen and (max-width: 650px) {
    font-size: 14px;
    margin-bottom: 10px;
    align-items: flex-start;
    .left{
      min-width: 150px;
    };
  }
}
